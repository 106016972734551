import type { Service } from 'src/types/enrichment'

const services: Service[] = [
    {
        type: 'company',
        title: 'Find the website from a company name',
        code: 'CUF',
        description:
            'Enrich your data by selecting a column with company names and countries from your uploaded Excel file. The service will provide the official website for each company.',
        icon: 'lucide:unlink-2',
        color: 'newError',
        link: 'https://cufinder.io/enrichment-engine/company-name-to-company-domain',
        credits: 1,
        inputs: [
            {
                field: 'companyName',
                title: 'Company Name',
                required: true
            }
        ],
        outputs: ['Company domain']
    },
    {
        type: 'company',
        title: 'Enrich Company',
        code: 'ENC',
        description:
            'Select a column with company names or company domains or company LinkedIn URLs from your uploaded file to get detailed company information, including industry, size, location, and more.',
        icon: 'lucide:building',
        color: 'newSecondary.light',
        link: 'https://cufinder.io/enrichment-engine/company-enrichment',
        credits: 3,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: [
            'Company name',
            'Company website',
            'Company employee count',
            'Company size',
            'Company industry',
            'Company description',
            'Company LinkedIn URL',
            'Company type',
            'Company domain',
            'Company country',
            'Company state',
            'Company city',
            'Company address',
            'Company founded year',
            'Company logo URL',
            'Company followers count'
        ]
    },
    {
        type: 'company',
        title: 'Find LinkedIn Company Information from Company Name',
        code: 'LCI',
        description:
            'Select a column with company names to enrich your data with company LinkedIn profiles, including the company’s LinkedIn URL, followers, location and company details.',
        icon: 'lucide:linkedin',
        color: 'newInfo.dark',
        link: 'https://cufinder.io/enrichment-engine/company-name-to-company-linkedin',
        credits: 3,
        inputs: [
            {
                field: 'companyName',
                title: 'Company Name',
                required: true
            }
        ],
        outputs: [
            'Company name',
            'Company website',
            'Company employee count',
            'Company size',
            'Company industry',
            'Company description',
            'Company LinkedIn URL',
            'Company type',
            'Company domain',
            'Company country',
            'Company state',
            'Company city',
            'Company address',
            'Company founded year',
            'Company logo URL',
            'Company followers count'
        ]
    },
    {
        type: 'company',
        title: 'Find Company Competitors',
        code: 'FCL',
        description:
            'Identify similar companies by selecting a column with company names or company domains or company LinkedIn URL. This service enriches your data with lookalike businesses for competitive analysis or expansion.',
        icon: 'lucide:layers',
        color: 'newSuccess',
        link: '',
        credits: 5,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: [
            'Company name',
            'Company website',
            'Company employee count',
            'Company size',
            'Company industry',
            'Company description',
            'Company LinkedIn URL',
            'Company type',
            'Company domain',
            'Company country',
            'Company state',
            'Company city',
            'Company address',
            'Company founded year',
            'Company logo URL',
            'Company followers count'
        ]
    },
    {
        type: 'company',
        title: 'Enrich company with latest fundraising data',
        code: 'ELF',
        description:
            'Get the latest fundraising details, such as funding rounds and investors, by selecting a column with company names or company domains or company LinkedIn URLs from your file.',
        icon: 'lucide:dollar-sign',
        color: 'newPastelBlue.dark1',
        link: '',
        credits: 4,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: [
            'Funding last round type',
            'Funding amount currency code',
            'Funding money raised',
            'Funding last round Investors URL'
        ]
    },
    {
        type: 'company',
        title: 'Find company annual revenue',
        code: 'CAR',
        description:
            'Select a column with company names or company domains or company LinkedIn URLs to retrieve their annual revenue figures.',
        icon: 'lucide:circle-dollar-sign',
        color: 'newInfo',
        link: '',
        credits: 3,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: ['Annual revenue']
    },
    {
        type: 'company',
        title: 'Find company name using domain',
        code: 'CND',
        description: 'Select a column containing company domains to get the associated company names.',
        icon: 'lucide:a-large-small',
        color: 'newPrimary',
        link: 'https://cufinder.io/enrichment-engine/company-domain-to-company-name',
        credits: 1,
        inputs: [
            {
                field: 'companyDomain',
                title: 'Company Domain',
                required: true
            }
        ],
        outputs: ['Company name']
    },
    {
        type: 'company',
        title: 'Find Business Email Address',
        code: 'FEA',
        description:
            'Select a column with names or domains or company LinkedIn URLs to get verified business email addresses for companies.',
        icon: 'lucide:mail',
        color: 'newPastelRed',
        link: 'https://cufinder.io/enrichment-engine/company-email-finder',
        credits: 1,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: ['Company email']
    },
    {
        type: 'company',
        title: 'Find Business Phone Number',
        code: 'FPA',
        description:
            'Retrieve accurate business phone numbers by selecting a column with company names or domains or comapny LinkedIn URLs from your uploaded file.',
        icon: 'lucide:phone',
        color: '#FE80FF',
        link: 'https://cufinder.io/enrichment-engine/company-phone-finder',
        credits: 2,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: ['Company phone']
    },
    {
        type: 'company',
        title: 'Find child companies (Subsidiaries)',
        code: 'FCC',
        description:
            'Select a column with parent company names or domains or company LinkedIn URLs to get a list of their subsidiaries.',
        icon: 'lucide:network',
        color: 'newPastelGold',
        link: '',
        credits: 3,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: ['Company’s child name']
    },
    {
        type: 'company',
        title: 'Find Technology Stack',
        code: 'FTS',
        description:
            'Discover the technologies used by companies by selecting a column with company names or domains or company LinkedIn URLs.',
        icon: 'lucide:code-xml',
        color: 'newSecondary',
        link: '',
        credits: 3,
        inputs: [
            {
                field: 'companyQuery',
                title: 'Company name or Company domain or Company LinkedIn URL',
                required: true
            }
        ],
        outputs: ['Technology name']
    },
    {
        type: 'contact',
        title: 'Enrich person from LinkedIn profile',
        code: 'EPP',
        description:
            'Select a column contains LinkedIn profile URLs to get enriched person details, including name, job title, company, and contact information.',
        icon: 'lucide:circle-user',
        color: 'newPastelRed',
        link: 'https://cufinder.io/enrichment-engine/contact-linkedin-to-info',
        credits: 1,
        inputs: [
            {
                field: 'linkedinURL',
                title: 'Person LinkedIn profile URL',
                required: true
            }
        ],
        outputs: [
            'Full name',
            'First name',
            'Last name',
            'LinkedIn URL',
            'Summary',
            'LinkedIn followers',
            'Facebook URL',
            'Twitter(X) URL',
            'Profile picture URL',
            'Country',
            'State',
            'City',
            'Job title',
            'Job title categories',
            'Company name',
            'Company LinkedIn URL',
            'Company website',
            'Company size',
            'Company industry',
            'Company Facebook URL',
            'Company Twitter(X) URL',
            'Company country',
            'Company state',
            'Company city'
        ]
    },
    {
        type: 'contact',
        title: 'Enrich person from Email',
        code: 'EPE',
        description:
            'Select a column with email addresses to get enriched personal details, including name, job title, company, and LinkedIn profile.',
        icon: 'carbon:email',
        color: 'newSecondary',
        link: 'https://cufinder.io/enrichment-engine/contact-email-to-name',
        credits: 4,
        inputs: [
            {
                field: 'email',
                title: 'Person business email',
                required: true
            }
        ],
        outputs: [
            'Full name',
            'First name',
            'Last name',
            'LinkedIn URL',
            'Summary',
            'LinkedIn followers',
            'Facebook URL',
            'Twitter(X) URL',
            'Profile picture URL',
            'Country',
            'State',
            'City',
            'Job title',
            'Job title categories',
            'Company name',
            'Company LinkedIn URL',
            'Company website',
            'Company size',
            'Company industry',
            'Company Facebook URL',
            'Company Twitter(X) URL',
            'Company country',
            'Company state',
            'Company city'
        ]
    },
    {
        type: 'contact',
        title: 'Find work email from LinkedIn profile',
        code: 'FWE',
        description:
            'Select a column with LinkedIn profile URLs to find verified work email addresses for your contacts.',
        icon: 'lucide:briefcase-business',
        color: 'newInfo.dark',
        link: '',
        credits: 2,
        inputs: [
            {
                field: 'linkedinUrl',
                title: 'Person LinkedIn URL',
                required: true
            }
        ],
        outputs: ['Business email']
    },
    {
        type: 'contact',
        title: 'Turn name and company into a fully enriched profile with email and phone',
        code: 'TEP',
        description:
            'Select columns with names and company names to create fully enriched profiles, including work email addresses and phone numbers.',
        icon: 'lucide:id-card',
        color: 'newSuccess',
        link: '',
        credits: 7,
        inputs: [
            {
                field: 'fullName',
                title: 'Full Name',
                required: true
            },
            {
                field: 'companyName',
                title: 'Company Name',
                required: true
            }
        ],
        outputs: [
            'Full name',
            'First name',
            'Last name',
            'LinkedIn URL',
            'Summary',
            'LinkedIn followers',
            'Facebook URL',
            'Twitter(X) URL',
            'Profile picture URL',
            'Country',
            'State',
            'City',
            'Job title',
            'Job title categories',
            'Company name',
            'Company LinkedIn URL',
            'Company website',
            'Company size',
            'Company industry',
            'Company Facebook URL',
            'Company Twitter(X) URL',
            'Company country',
            'Company state',
            'Company city',
            'Work email',
            'Phone number'
        ]
    }
]

export default services
